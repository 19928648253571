<template>
    <ts-panel>
        <ts-panel-wrapper class="tw-space-y-3">
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("employeeProfile.employmentDate")
                    }}</label>
                    <a-date-picker
                        v-model="model.employment_date"
												format="DD-MM-YYYY"
												valueFormat="DD-MM-YYYY"
                        :placeholder="$t('employeeProfile.employmentDate')"
                        class="tw-w-full"
                    ></a-date-picker>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('employment_date')"
                    >
                        {{ errors.first("employment_date") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label>{{
                        $t("employeeProfile.probationEndDate")
                    }}</label>
                    <a-date-picker
                        v-model="model.probation_end_date"
												format="DD-MM-YYYY"
												valueFormat="DD-MM-YYYY"												
                        :placeholder="$t('employeeProfile.probationEndDate')"
                        class="tw-w-full"
                    ></a-date-picker>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('probation_end_date')"
                    >
                        {{ errors.first("probation_end_date") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("employeeProfile.status")
                    }}</label>
                    <a-select
                        v-model="model.status_id"
                        class="tw-w-full"
												:options="status"
                        :placeholder="$t('employeeProfile.status')"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('status_id')"
                    >
                        {{ errors.first("status_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("employeeProfile.effectiveDate")
                    }}</label>
                    <a-date-picker
                        v-model="model.status_effective_date"
												format="DD-MM-YYYY"
												valueFormat="DD-MM-YYYY"												
                        :placeholder="$t('employeeProfile.effectiveDate')"
                        class="tw-w-full"
												:disabled="model.status_id == 1"
                    ></a-date-picker>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('status_effective_date')"
                    >
                        {{ errors.first("status_effective_date") }}
                    </div>
                </div>
            </div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapState } from 'vuex';
export default {
    name: "hireDate",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            model: {}
        };
    },
		computed: {
			...mapState('hr/employeeProfile', ['formModels']),
			status() {
				if(!this.formModels.employeeStatus) return []
				
				return this.formModels.employeeStatus.map(el => ({
					value: el.status_id,
					label: el.status
				}))
			}
		},
    created() {
        this.model = this.value;
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
